// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

img.banner-preview {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.rte-container {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.multi-select-input > div {
  border: 1px solid #e4e7ea;

  &:hover {
    border: 1px solid #e4e7ea;
  }
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.sidebar .nav-dropdown-items {
  margin-left: 10px;
}

/* dashboard.css */
.earning-text {
  font-size: 24px;
  color: #ff9800; /* Change the color to your desired color */
  font-weight: bold;
  margin-right: 4px; /* Add some space between the "$" symbol and the amount */
}
