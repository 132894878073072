/* @import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);
body {
  font-family: "Lato", sans-serif;
} */

.image-upload-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.file-upload-wrapper {
  position: relative;
  width: 80%;
  border: 1px solid #e4e7ea;
  height: 60px;
}

.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 10px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: 40px;
  line-height: 40px;
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
}

.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 60px;
  background: #4daf7c;
  color: #fff;
  font-weight: 700;
  z-index: 25;
  font-size: 16px;
  line-height: 60px;
  padding: 0 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}

.file-upload-wrapper:hover:before {
  background: #3d8c63;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.image-div {
  height: 320px;
  background-color: #c9c9c9ed;
  margin-bottom: 15px;
}

.image-thumbnail-div {
  height: 220px;
  background-color: #c9c9c9ed;
  margin-bottom: 15px;
}

.image-div img, .image-thumbnail-div img {
  height: 100%;
  width: 100%;
}

.top-right-profile-name {
  margin-top: 7px;
  padding-right: 35px;
  color: #0069bd;
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 767px) {
  .top-right-profile-name {
    display: none;
  }
}

.rt-td, .rt-th {
  border: 1px solid #c8ced3;
  white-space: inherit !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
}

.left-padding-10px {
  padding-left: 10px !important;
}

.order-user-detail .table-sm th, .order-user-detail .table-sm td {
  padding: 0.4rem !important;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.font-weight-600 {
  font-weight: 600;
}

.dashboard-order-percentage {
  background: #fff;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 1px 4px 5px #ddd;
  padding: 20px 10px 0px 10px;
  margin-right: 5px;
}

.react-daterange-picker__inputGroup {
  padding: 0 5px !important;
}

.react-daterange-picker__range-divider {
  margin-top: 3px !important;
}

.invoice-print-btn {
  width: 10%;
  margin-bottom: 38px;
}

.invoice-print-btn i {
  padding-right: 5px;
}

/* Invoice */


.invoice-page-wrapper {
  font-size: 14px;
  color: #000;
  margin: 0 auto;
  padding: 100px 60px 60px 80px;
  background: #fff;
  height: 100%;
}

.invoice-page-wrapper a {
  text-decoration: none !important;
}

.invoice-page-wrapper .invoice-logo img {
  width: 90%;
}


.invoice-page-wrapper table.ipl-table {
  width: 100%;
  table-layout: fixed;
  /* max-width: 900px; */
  margin-top: 30px;
}

.invoice-page-wrapper table.ipl-table tr th {
  background: #000;
  color: #fff;
  padding: 5px 20px;
}

.invoice-page-wrapper table.ipl-table tr td {
  border-bottom: 1px solid #ddd;
  padding: 6px 6px !important;
  width: 20%;
}

.invoice-page-wrapper table.ipl-table tr th:nth-child(3), .invoice-page-wrapper table.ipl-table tr th:nth-child(4), .invoice-page-wrapper table.ipl-table tr td:nth-child(3), .invoice-page-wrapper table.ipl-table tr td:nth-child(4) {
  text-align: right;
}

.invoice-page-wrapper table.ipl-table tr th:nth-child(2), .invoice-page-wrapper table.ipl-table tr td:nth-child(2) {
  text-align: center;
}

.invoice-page-wrapper table.ipl-table tr th:nth-child(1), .invoice-page-wrapper table.ipl-table tr td:nth-child(1) {
  width: 40%;
}

.invoice-page-wrapper table.ipl-table tr td.blank {
  border-bottom-color: transparent;
}

.invoice-page-wrapper .customer-note {
  margin-top: 25px;
  margin-bottom: 80px;
  padding-bottom: 10px;
}

.invoice-page-wrapper .customer-note b {
  font-style: normal;
  margin-right: 5px;
}

.invoice-page-wrapper .footer-note {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  position: fixed;
  bottom: 0;
}


.invoice-page-wrapper .Bkash-note {
  margin-top: 480px !important;
  margin-bottom: 45px;
  position: absolute;
  bottom: 5px;
}

.center {
  display: block;
  margin-left: auto;
  margin-top: -60px;
  margin-right: auto;
  /* width: 50px; */
  /* width: 8%; */
}

.invoice-page-wrapper table.ipl-table .product-name {
  font-weight: 700;
  margin-bottom: 4px !important;
}

.invoice-page-wrapper table.ipl-table span.sku {
  font-size: 10px;
  font-weight: 400;
}

.invoice-page-wrapper .inv-address {
  margin: 10px 1px;
  padding-left: 85px;
}


.invoice-page-wrapper .inv-address a {
  color: #333;
}

.invoice-page-wrapper .inva-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}

.invoice-page-wrapper .inva-item i {
  width: 16px;
  margin-right: 5px;
}

.invoice-page-wrapper .inva-item a {
  width: calc(100% - 16px - 5px);
}

.invoice-page-wrapper .invoice-page-title h3 {
  font-size: 1.65rem;
  text-transform: uppercase;
  padding-left: 3px;
}

.invoice-infos {
  margin-top: 30px;
  margin-bottom: -50px;
}


.invoice-infos-right table {
  width: 100%;
}

.invoice-infos-right table td.payment-method {
  font-weight: 700;
}


.invoice-page-wrapper .invoice-infos-middle p {
  margin: 0 0 2px;
}


.invoice-page-wrapper .invoice-infos-left p a {
  color: #333;
}

.invoice-page-wrapper .invoice-infos-left p {
  margin: 0 0 2px;
}

.invoice-page-wrapper .invoice-infos-left {
  margin-bottom: 10px;
  padding-left: 19px;
}

.invoice-page-wrapper .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.invoice-page-wrapper .col-lg-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.invoice-page-wrapper .col-lg-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
}

.invoice-page-wrapper .col-lg-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.invoice-page-wrapper .col-lg-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.invoice-page-wrapper .col-lg-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 30px;
}



table.ipl-table.total-summary tr td, 
table.ipl-table.total-summary tr th, 
.footer-group{
    page-break-inside: avoid;
}
.sticky-footer {
    position: fixed;
    bottom: 50px;
}

@media print {
    .page-break:not(:last-child)  { page-break-after: always; }
    .page-break-before { page-break-before:always; }
    @page {
        size: A4;
    }
}

.react-daterange-picker__inputGroup {
  min-width: auto !important;
}

.light-text {
  font-size: 10px;
  font-weight: 400;
}

.admin-permissions-row .col {
  margin: 5px;
  padding-top: 10px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

/* Chrome, Safari, Edge, Opera */
.number-input-no-arrow::-webkit-outer-spin-button,
.number-input-no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input-no-arrow[type=number] {
  -moz-appearance: textfield;
}

.hide {
  display: none !important;
}

.update-history-modal-content {
  height: 77vh;
  overflow: auto;
}

.beautiful-scrollbar::-webkit-scrollbar {
  width: .5em;
}
 
.beautiful-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
 
.beautiful-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}