// Here you can add other styles

.react-datetime-picker__wrapper {
  padding: 3px !important;
  border: thin solid #dfdfdf !important;
  border-radius: 3px !important;
}

//Edit Delete button
.action-icons {
  width: 35px;
  font-size: 20px;
  padding: 12px;
  cursor: pointer;
}

.edit-icon {
  color: rgb(40, 167, 69);
}

.delete-icon {
  color: rgb(228, 86, 110);
}
